import React from 'react';

import { PromoType, getPromoItems } from '../../../../sharedModules/getPromoItems';
import type { Deal } from '../../../types/Deal';
import getPriceType from '../../../utils/getPriceType';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { Promos } from '../../GenericComponents/Promos/loadable';

import styles from './styles/price-subscriptions-deal.css';

interface PriceSubscriptionsDealProps {
  deal: Deal;
}

const PriceSubscriptionsDeal: React.FC<PriceSubscriptionsDealProps> = ({ deal }) => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        genericSharedComponents: { DisplayName, DisplayPrice },
      } = value;
      const promoItems = getPromoItems(deal, PromoType.FLAGS, false);

      return (
        <div className={styles.container}>
          {promoItems.length > 0 && (
            <Promos items={promoItems} className="price-widget-deal-subs" />
          )}
          <div className={styles.main}>
            <DisplayName deal={deal} className={styles['display-name']} />
            <DisplayPrice deal={deal} type={getPriceType(deal)} className="price-subscriptions" />
          </div>
        </div>
      );
    }}
  </Consumer>
);

export default PriceSubscriptionsDeal;
