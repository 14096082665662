import React from 'react';

import { getWidgetInitialiser } from '../../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../../utils/loadCSS';
import { InitialiserProps, WidgetWrapper } from '../../../types/InitialiserProps';
import type { WidgetProps } from '../../../types/WidgetProps';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import PriceRetail from '../Retail/PriceRetail';
import PriceSubscriptions from '../Subscriptions/PriceSubscriptions';

loadCSS('price');

const Price: React.FC<WidgetProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetStandard },
  } = props;
  return (
    <HawkWidgetStandard {...props}>
      <Consumer>
        {(value): JSX.Element => {
          const { activeTab, site } = value;

          switch (activeTab?.category) {
            case 'subscriptions':
              return (
                <PriceSubscriptions
                  className={site.type === 'responsive' ? 'leftLayout' : 'leftLayoutProgressive'}
                />
              );
            default:
              return <PriceRetail />;
          }
        }}
      </Consumer>
    </HawkWidgetStandard>
  );
};

Price.getInitialiserProps = async ({
  renderProps: { el, site, articleUrl, ...restRenderProps },
  attributes,
  getWidgetResponse,
  getSeasonalResponse,
  getAiredaleFeeds,
  getModelSuggestions,
  postForWidgetResponse,
  editorial,
  defaultParams,
  url,
  origin,
  dataLinkMerchant,
}): InitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    url,
    origin,
    dataLinkMerchant,
    site,
    getWidgetResponse,
    getSeasonalResponse,
    getAiredaleFeeds,
    getModelSuggestions,
    postForWidgetResponse,
    placeholder: el,
    articleUrl,
    attributes,
    ...restRenderProps,
  });

  return { props, type, widgetWrapper: WidgetWrapper.DIV };
};

export default Price;
